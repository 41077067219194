import {
	ICustomTableMapping,
	ICustomTableHeader,
} from '../../types/interfaces';
import { E_DeviceType, TYPES_OF_ELEMENTS } from '../../constants/appConstants';
import { getDeviceType } from '../../utilities/utils';

const isMobile = getDeviceType() === E_DeviceType.Mobile;

export enum E_AdjustmentOption {
	All = 'all',
	Adjusted = 'adjusted',
	NonAdjusted = 'nonAdjusted',
}

export enum E_ContractType {
	All = 'all',
	Weekly = 'weekly',
	Monthly = 'monthly',
	Quarterly = 'quarterly',
}

export enum E_OptionChainDisplayDropdownOptions {
	CallsAndPuts = 'all',
	CallsOnly = 'call',
	PutsOnly = 'put',
	ITMCalls = 'itmCall',
	OTMCalls = 'otmCall',
	ITMPuts = 'itmPut',
	OTMPuts = 'otmPut',
}

export const OptionChainDisplayDropdownOptions = [
	{
		value: 'Calls & Puts',
		key: E_OptionChainDisplayDropdownOptions.CallsAndPuts,
	},
	{
		value: 'Calls Only',
		key: E_OptionChainDisplayDropdownOptions.CallsOnly,
	},
	{
		value: 'Puts Only',
		key: E_OptionChainDisplayDropdownOptions.PutsOnly,
	},
	{
		value: 'ITM Calls',
		key: E_OptionChainDisplayDropdownOptions.ITMCalls,
	},
	{
		value: 'OTM Calls',
		key: E_OptionChainDisplayDropdownOptions.OTMCalls,
	},
	{
		value: 'ITM Puts',
		key: E_OptionChainDisplayDropdownOptions.ITMPuts,
	},
	{
		value: 'OTM Puts',
		key: E_OptionChainDisplayDropdownOptions.OTMPuts,
	},
];

//Strikes Options
export const StrikesMobileDropdownOptions = [
	{
		value: '6 Strikes',
		key: '6',
	},
	{
		value: '12 Strikes',
		key: '12',
	},
	{
		value: '18 Strikes',
		key: '18',
	},
	{
		value: 'All Strikes',
		key: 'all',
	},
];

// OptionsQuoteBar
export interface IOptionsQuoteItem {
	[key: string]: any;
	last?: number;
	dollarChange?: number;
	percentChange?: number;
	volume?: number;
	prevClose?: number;
	open?: number;
	dayHigh?: number;
	dayLow?: number;
}

export const OptionQuoteBarKeys = {
	Last: 'last',
	DollarChange: 'dollarChange',
	PercentChange: 'percentChange',
	Volume: 'volume',
	PrevClose: 'prevClose',
	Open: 'open',
	DayHigh: 'dayHigh',
	DayLow: 'dayLow',
};

export const OptionQuoteBarHeader = [
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'LAST',
		key: OptionQuoteBarKeys.Last,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'CHANGE',
		key: OptionQuoteBarKeys.DollarChange,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'VOLUME',
		key: OptionQuoteBarKeys.Volume,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'PREV CLOSE',
		key: OptionQuoteBarKeys.PrevClose,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'OPEN',
		key: OptionQuoteBarKeys.Open,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'DAY HIGH/LOW',
		key: OptionQuoteBarKeys.DayHigh,
	},
];

export const HeaderGroups = {
	Change: 'change',
	DayHighLow: 'highLow',
	LastTradePrice: 'lastTradePrice',
	LastTradeDate: 'lastTradeDate',
};

export const OptionQuoteBarHeaderMapping = [
	{
		key: OptionQuoteBarKeys.Last,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionQuoteBarKeys.DollarChange,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		group: HeaderGroups.Change,
	},
	{
		key: OptionQuoteBarKeys.PercentChange,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		skipRenderingCell: true,
		showPercent: true,
		group: HeaderGroups.Change,
	},
	{
		key: OptionQuoteBarKeys.Volume,
		commaSeparated: true,
		type: TYPES_OF_ELEMENTS.NUMBER,
		precision: 0,
	},
	{
		key: OptionQuoteBarKeys.PrevClose,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionQuoteBarKeys.Open,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionQuoteBarKeys.DayHigh,
		group: HeaderGroups.DayHighLow,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionQuoteBarKeys.DayLow,
		group: HeaderGroups.DayHighLow,
		type: TYPES_OF_ELEMENTS.NUMBER,
		skipRenderingCell: true,
	},
];

// OptionChainTable
export const OptionChainTableKeys = {
	Symbol: 'symbol',
	Last: 'last',
	Change: 'change',
	Bid: 'bid',
	Ask: 'ask',
	Volume: 'volume',
	OpenInterest: 'openInterest',
	StrikePrice: 'strikePrice',
};

export const OptionChainTableHeader: ICustomTableHeader[] = [
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Symbol',
		key: OptionChainTableKeys.Symbol,
		isDarkGrey: true,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: isMobile ? 'Last Price' : 'Last',
		isDarkGrey: true,
		key: OptionChainTableKeys.Last,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Change',
		isDarkGrey: true,
		key: OptionChainTableKeys.Change,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Bid',
		isDarkGrey: true,
		key: OptionChainTableKeys.Bid,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Ask',
		isDarkGrey: true,
		key: OptionChainTableKeys.Ask,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Volume',
		isDarkGrey: true,
		key: OptionChainTableKeys.Volume,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Open Interest',
		isDarkGrey: true,
		key: OptionChainTableKeys.OpenInterest,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: isMobile ? 'Strikes' : 'Strike Price',
		isDarkGrey: true,
		key: OptionChainTableKeys.StrikePrice,
		className: 'strike-cell-header',
	},
];

export const OptionChainTableMapping: ICustomTableMapping[] = [
	{
		key: OptionChainTableKeys.Symbol,
		type: TYPES_OF_ELEMENTS.LINK_LABEL,
		className: 'click-icon',
		isCalculated: true,
	},
	{
		key: OptionChainTableKeys.Last,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionChainTableKeys.Change,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
	},
	{
		key: OptionChainTableKeys.Bid,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionChainTableKeys.Ask,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionChainTableKeys.Volume,
		commaSeparated: true,
		type: TYPES_OF_ELEMENTS.NUMBER,
		precision: 0,
	},
	{
		key: OptionChainTableKeys.OpenInterest,
		type: TYPES_OF_ELEMENTS.NUMBER,
		className: 'open-interest',
	},
	{
		key: OptionChainTableKeys.StrikePrice,
		type: TYPES_OF_ELEMENTS.NUMBER,
		className: 'strike-cell',
	},
];

// OptionQuoteBar
export interface IPuBidOptionQuoteItem {
	[key: string]: any;
	lastTradePrice?: number;
	lastTradeTime?: string;
	lastTradeDate?: string;
	dollarChange?: number;
	percentChange?: number;
	volume?: number;
	bid?: number;
	ask?: number;
	openPrice?: number;
	openInterest?: number;
}

export const OptionQuoteKeys = {
	LastTradePrice: 'lastTradePrice',
	LastTradeTime: 'lastTradeTime',
	LastTradeDate: 'lastTradeDate',
	DollarChange: 'dollarChange',
	PercentChange: 'percentChange',
	Volume: 'volume',
	Bid: 'bid',
	Ask: 'ask',
	OpenPrice: 'openPrice',
	OpenInterest: 'openInterest',
};

export const OptionQuoteHeader = [
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'LAST TRADE PRICE',
		key: OptionQuoteKeys.LastTradePrice,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		key: OptionQuoteKeys.LastTradeDate,
		label: 'LAST TRADE TIME',
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		key: OptionQuoteKeys.LastTradeTime,
		label: 'LAST TRADE TIME',
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'CHANGE',
		key: OptionQuoteKeys.DollarChange,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'VOLUME',
		key: OptionQuoteKeys.Volume,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'BID',
		key: OptionQuoteKeys.Bid,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'ASK',
		key: OptionQuoteKeys.Ask,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'OPEN PRICE',
		key: OptionQuoteKeys.OpenPrice,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'OPEN INTEREST',
		key: OptionQuoteKeys.OpenInterest,
	},
];

export const OptionQuoteHeaderMapping = [
	{
		key: OptionQuoteKeys.LastTradePrice,
		type: TYPES_OF_ELEMENTS.NUMBER,
		group: HeaderGroups.LastTradePrice,
		fontType: 'neue-bold',
	},
	{
		key: OptionQuoteKeys.LastTradePrice,
		type: TYPES_OF_ELEMENTS.LABEL,
		group: HeaderGroups.LastTradePrice,
		fontType: 'neue-bold',
		skipRenderingCell: true,
	},
	{
		key: OptionQuoteKeys.LastTradeDate,
		fontType: 'neue-bold',
		group: HeaderGroups.LastTradeDate,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
	{
		key: OptionQuoteKeys.LastTradeTime,
		fontType: 'neue-bold',
		group: HeaderGroups.LastTradeDate,
		type: TYPES_OF_ELEMENTS.LABEL,
		skipRenderingCell: true,
	},
	{
		key: OptionQuoteKeys.DollarChange,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		fontType: 'neue-bold',
		group: HeaderGroups.Change,
	},
	{
		key: OptionQuoteKeys.PercentChange,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		skipRenderingCell: true,
		fontType: 'neue-bold',
		showPercent: true,
		group: HeaderGroups.Change,
	},
	{
		key: OptionQuoteKeys.Volume,
		commaSeparated: true,
		type: TYPES_OF_ELEMENTS.NUMBER,
		fontType: 'neue-bold',
		precision: 0,
	},
	{
		key: OptionQuoteKeys.Bid,
		fontType: 'neue-bold',
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionQuoteKeys.Ask,
		fontType: 'neue-bold',
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionQuoteKeys.OpenPrice,
		fontType: 'neue-bold',
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionQuoteKeys.OpenInterest,
		fontType: 'neue-bold',
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
];

//Option Profile
export interface IOptionProfile {
	ExpirationDate: string;
	StrikePrice: number;
	IntrinsicValue?: number;
	TenDayVolume: number | '--';
	SharesPerContract: number | string;
	TodaysHighLow: string;
	PreviousHighLow: string;
	PreviousNetChange: number | '--';
	PreviousClosePrice: number | '--';
	ContractHighLow: string;
	LastTrade?: string;
}

export const OptionProfileKeys = {
	ExpirationDate: 'ExpirationDate',
	StrikePrice: 'StrikePrice',
	IntrinsicValue: 'IntrinsicValue',
	TenDayVolume: 'TenDayVolume',
	SharesPerContract: 'SharesPerContract',
	TodaysHighLow: 'TodaysHighLow',
	PreviousHighLow: 'PreviousHighLow',
	PreviousNetChange: 'PreviousNetChange',
	PreviousClosePrice: 'PreviousClosePrice',
	ContractHighLow: 'ContractHighLow',
	LastTrade: 'LastTrade',
};

export const OptionProfileHeader: ICustomTableHeader[] = [
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Expiration Date',
		key: OptionProfileKeys.ExpirationDate,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		key: OptionProfileKeys.StrikePrice,
		label: 'Strike Price',
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Intrinsic Value',
		key: OptionProfileKeys.IntrinsicValue,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: '10 Day Volume',
		key: OptionProfileKeys.TenDayVolume,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Shares per Contract',
		key: OptionProfileKeys.SharesPerContract,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: `Today’s High/Low`,
		key: OptionProfileKeys.TodaysHighLow,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Previous Day High/Low',
		key: OptionProfileKeys.PreviousHighLow,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Previous Day Net Change',
		key: OptionProfileKeys.PreviousNetChange,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Previous Close Price',
		key: OptionProfileKeys.PreviousClosePrice,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Contract High/Low',
		key: OptionProfileKeys.ContractHighLow,
	},
];

export const OptionProfileMapping: ICustomTableMapping[] = [
	{
		key: OptionProfileKeys.ExpirationDate,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
	{
		key: OptionProfileKeys.StrikePrice,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionProfileKeys.IntrinsicValue,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionProfileKeys.TenDayVolume,
		type: TYPES_OF_ELEMENTS.NUMBER,
		precision: 0,
		commaSeparated: true,
	},
	{
		key: OptionProfileKeys.SharesPerContract,
		precision: 0,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionProfileKeys.TodaysHighLow,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
	{
		key: OptionProfileKeys.PreviousHighLow,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
	{
		key: OptionProfileKeys.PreviousNetChange,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionProfileKeys.PreviousClosePrice,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionProfileKeys.ContractHighLow,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
];

export const OPTIONS_ACTIONS = {
	VIEW_OPTION_PROFILE: 'view-option',
	OPTIONS_HOME_PAGE: 'options-home',
	OPEN_MOBILE_CHANGE_VIEW: 'open-mobile-change-view',
	CLOSE_MOBILE_CHANGE_VIEW: 'close-mobile-change-view',
};

export const LastTradePriceLabel = `Options Price Reporting Authority BBO`;

//Option Popover
export const OptionPopoverHeader: ICustomTableHeader[] = [
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Intrinsic Value',
		key: OptionProfileKeys.IntrinsicValue,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: '10 Day Volume',
		key: OptionProfileKeys.TenDayVolume,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Shares per Contract',
		key: OptionProfileKeys.SharesPerContract,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Contract High/Low',
		key: OptionProfileKeys.ContractHighLow,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Previous Day High/Low',
		key: OptionProfileKeys.PreviousHighLow,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Previous Day Net Change (%)',
		key: OptionProfileKeys.PreviousNetChange,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Previous Close',
		key: OptionProfileKeys.PreviousClosePrice,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		label: 'Last Trade',
		key: OptionProfileKeys.LastTrade,
	},
];

export const OptionPopoverMapping: ICustomTableMapping[] = [
	{
		key: OptionProfileKeys.IntrinsicValue,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionProfileKeys.TenDayVolume,
		type: TYPES_OF_ELEMENTS.NUMBER,
		precision: 0,
		commaSeparated: true,
	},
	{
		key: OptionProfileKeys.SharesPerContract,
		type: TYPES_OF_ELEMENTS.NUMBER,
		precision: 0,
	},
	{
		key: OptionProfileKeys.ContractHighLow,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
	{
		key: OptionProfileKeys.PreviousHighLow,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
	{
		key: OptionProfileKeys.PreviousNetChange,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionProfileKeys.PreviousClosePrice,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: OptionProfileKeys.LastTrade,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
];

export const InTheMoneyIndicator = 'IN THE MONEY';

export const MobileChangeViewOptions = [
	{ label: 'Last Price', value: 'last' },
	{ label: 'Change', value: 'change' },
	{ label: 'Bid', value: 'bid' },
	{ label: 'Ask', value: 'ask' },
	{ label: 'Volume', value: 'volume' },
	{ label: 'Open Interest', value: 'openInterest' },
];
