import { ENABLE_LOGGING } from '../constants/appConstants';

export const logInfo = (message: any, data: any = null): any => {
	if (!ENABLE_LOGGING) return;

	console.info('[MD]', message, window?.MD?.ENV == 'production' ? {} : data);
};

export const logError = (message: any) => {
	if (!ENABLE_LOGGING) return;
	console.error('[MD]', message);
};

export const logWarning = (message: any) => {
	if (!ENABLE_LOGGING) return;
	console.warn('[MD]', message);
};

export const logDebugInfo = (message: any) => {
	if (!ENABLE_LOGGING) return;
	console.debug('[MD]', message);
};
